import { createContext, ReactNode, useEffect } from 'react';

import { useQuery } from 'react-query';
import Router from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';

import { Paths } from '@/types/paths';
import { useApi } from '@/hooks/useApi';

export const UserProfileContext = createContext(undefined);

type Props = {
  children: ReactNode;
};

export function UserProfileProvider({ children }: Props) {
  const { user: userAuth0 } = useAuth0();
  const { getCurrentUser } = useApi();

  const {
    data: currentUser,
    isLoading,
    refetch
  } = useQuery('getCurrentUser', () => getCurrentUser(), {
    enabled: !!userAuth0
  });

  useEffect(() => {
    if (
      currentUser &&
      currentUser.user_profile &&
      !currentUser.user_profile?.wizard_completed
    ) {
      Router.push({
        pathname: Paths.Register
      });
    }
  }, [currentUser]);

  return (
    <UserProfileContext.Provider
      value={{
        user: currentUser,
        userIsLoading: isLoading,
        refetchUser: refetch
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}
