import { FC, useEffect } from 'react';

import { Provider as ReduxProvider } from 'react-redux';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import nProgress from 'nprogress';
import { SnackbarProvider } from 'notistack';
import 'nprogress/nprogress.css';
import Script from 'next/script';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { datadogRum } from '@datadog/browser-rum';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import { NextPageWithLayout } from 'src/types';
import ThemeProvider from 'src/theme/ThemeProvider';
import { store } from 'src/store';
import useScrollTop from 'src/hooks/useScrollTop';
import createEmotionCache from 'src/createEmotionCache';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { isProduction } from '@/utils/isProduction';
import { UserProfileProvider } from '@/contexts/UserProfileContext';
import { SegmentProvider } from '@/contexts/SegmentContext';
import { AppDataProvider } from '@/contexts/AppData';
import { AbilityProvider } from '@/contexts/AbilityContext';
import 'src/utils/chart';
import Loader from '@/components/Loader';
import { CoosiesBanned } from '@/components/CookiesBanner';
import Status500 from './status/500';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { auth0Config, dataDogConfig } from '../config';
import './style.css';
import 'react-horizontal-scrolling-menu/dist/styles.css';

const clientSideEmotionCache = createEmotionCache();

interface AuthConsumerProps {
  Component: NextPageWithLayout;
  pageProps: object;
}

export const AuthConsumer: FC<AuthConsumerProps> = ({
  Component,
  pageProps
}) => {
  const { isLoading } = useAuth0();

  const getLayout = Component.getLayout ?? ((page) => page);

  return isLoading ? <Loader /> : getLayout(<Component {...pageProps} />);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

function MyApp(props: MyAppProps) {
  const { emotionCache = clientSideEmotionCache } = props;

  useScrollTop();
  const { replace } = useRouter();

  Router.events.on('routeChangeStart', nProgress.start);
  Router.events.on('routeChangeError', nProgress.done);
  Router.events.on('routeChangeComplete', nProgress.done);

  const startDataDogUiMonitoring = () => {
    if (isProduction) {
      datadogRum.init({
        applicationId: dataDogConfig.application_id,
        clientToken: dataDogConfig.client_token,
        site: 'datadoghq.com',
        service: 'supplify-app-prod',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
        enableExperimentalFeatures: ['clickmap']
      });

      datadogRum.startSessionReplayRecording();
    }
  };

  useEffect(() => {
    startDataDogUiMonitoring();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={
        <ThemeProvider>
          <Status500 />
        </ThemeProvider>
      }
    >
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Supplify</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta property="og:url" content="https://getsupplify.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Supplify" />
          <meta
            property="og:description"
            content="Join the biggest supply chain tech platform"
          />
          <meta
            property="og:image"
            content="/supplify_social_preview_image.png"
          />
        </Head>

        {isProduction && (
          <>
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />

            <Script id="google-analytics" strategy="afterInteractive">
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
            </Script>
          </>
        )}

        <ReduxProvider store={store}>
          <SegmentProvider>
            <QueryClientProvider client={queryClient}>
              <AppDataProvider>
                <SidebarProvider>
                  <ThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Auth0Provider
                        domain={auth0Config.domain}
                        clientId={auth0Config.client_id}
                        authorizationParams={{
                          redirect_uri: auth0Config.callback,
                          audience: auth0Config.audience
                        }}
                        onRedirectCallback={async (appState) => {
                          await replace(appState?.returnTo || '/');
                        }}
                      >
                        <UserProfileProvider>
                          <AbilityProvider>
                            <SnackbarProvider
                              maxSnack={6}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                            >
                              <CoosiesBanned />
                              <CssBaseline />
                              <AuthConsumer
                                Component={props.Component}
                                pageProps={props.pageProps}
                              />
                            </SnackbarProvider>
                          </AbilityProvider>
                        </UserProfileProvider>
                      </Auth0Provider>
                    </LocalizationProvider>
                  </ThemeProvider>
                </SidebarProvider>
              </AppDataProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </SegmentProvider>
        </ReduxProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(MyApp);
