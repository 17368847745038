import { FC } from 'react';

import CookieConsent from 'react-cookie-consent';
import { alpha, useTheme } from '@mui/material';

export const CoosiesBanned: FC = () => {
  const theme = useTheme();

  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="permission to use cookies"
      declineButtonText="Decline"
      enableDeclineButton
      style={{ background: alpha(theme.colors.primary.main, 0.95) }}
      //   debug
      buttonStyle={{
        color: 'cyan',
        fontSize: '13px',
        width: '100px',
        background: 'none'
      }}
      declineButtonStyle={{
        color: 'white',
        fontSize: '13px',
        width: '100px',
        background: 'none'
      }}
      expires={365}
    >
      This website uses cookies to enhance the user experience.{' '}
    </CookieConsent>
  );
};
