export const apiURL = process.env.NEXT_PUBLIC_API_URL;

export const countryStateCityApi = {
  apiKey: process.env.NEXT_PUBLIC_CITY_API_KEY
};

export const auth0Config = {
  client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  callback: process.env.NEXT_PUBLIC_AUTH0_CALLBACK
};

export const dataDogConfig = {
  application_id: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  client_token: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
};
