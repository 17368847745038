import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { apiURL, auth0Config } from '../../config';

export const useApi = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // *** COMPANIES FILTERS ***

  const getCompaniesFilters = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await fetch(`${apiURL}/v1/companies/filters`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const getFilters = async () => {
    const response = await axios.get(`${apiURL}/v1/companies/filters`);
    return response.data;
  };

  // **  INVESTORS FILTERS ** //

  const getInvestorsFilters = async () => {
    const response = await axios.get(`${apiURL}/v1/investors/filters`);
    return response.data;
  };

  // *** CONSTANTS ***

  const getConstants = async () => {
    const response = await axios.get(`${apiURL}/v1/constants`);
    return response.data;
  };

  // *** TAGS AND CATEGORIES ***

  const getAllTags = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.get(`${apiURL}/v1/tags`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const getAllCategories = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.get(`${apiURL}/v1/categories`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const addNewCategory = async (user_id, name) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(
      `${apiURL}/v1/categories`,
      { user_id, name },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const addNewTag = async (user_id, name) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(
      `${apiURL}/v1/tags`,
      { user_id, name },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  // *** PROJECTS ***

  const getProjectById = async (id) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.get(`${apiURL}/v1/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const getProjects = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.get(`${apiURL}/v1/projects/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const postProject = async (project) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/projects`, project, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const putProject = async (project, uuid) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.put(`${apiURL}/v1/projects/${uuid}`, project, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** COMPANIES ***
  const getCompanies = async (queryParams) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Config.audience
        }
      });

      const response = await axios.get(`${apiURL}/v1/companies${queryParams}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    }

    const response = await axios.get(`${apiURL}/v1/companies${queryParams}`);
    return response.data;
  };

  const getCompanyByUuid = async (uuid) => {
    const response = await axios.get(`${apiURL}/v1/companies/${uuid}`);
    return response.data;
  };

  const postCompany = async (company) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/companies`, company, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const deleteCompany = async (company) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.delete(`${apiURL}/v1/companies/${company}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const putCompany = async (uuid, company) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.put(`${apiURL}/v1/companies/${uuid}`, company, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const getCompanyDetails = async (uuid) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const companyDetails = await axios.get(
      `${apiURL}/v1/companies/${uuid}/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return companyDetails.data;
  };

  const postCompanyDetails = async (uuid, companyDetails) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const response = await axios.post(
      `${apiURL}/v1/companies/${uuid}/details`,
      companyDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  };

  const putCompanyDetails = async (uuid, companyDetails) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const response = await axios.put(
      `${apiURL}/v1/companies/${uuid}/details`,
      companyDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  };

  const exportCompanies = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/companies/bulk_export_csv`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** INVESTORS ***
  const getInvestors = async (queryParams) => {
    const response = await axios.get(`${apiURL}/v1/investors${queryParams}`);
    return response.data;
  };

  // *** ACQUISITIONS ***
  const getAcquisitions = async (queryParams) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Config.audience
        }
      });

      const response = await axios.get(
        `${apiURL}/v1/acquisitions${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return response.data;
    }

    const response = await axios.get(`${apiURL}/v1/acquisitions${queryParams}`);
    return response.data;
  };

  const getAcquisitionsFilters = async () => {
    const response = await axios.get(`${apiURL}/v1/acquisitions/filters`);
    return response.data;
  };

  // *** FUNDING ROUNDS ***
  const getFundingRounds = async (queryParams) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Config.audience
        }
      });

      const response = await axios.get(
        `${apiURL}/v1/funding_rounds${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return response.data;
    }

    const response = await axios.get(
      `${apiURL}/v1/funding_rounds${queryParams}`
    );
    return response.data;
  };

  const getFundingRoundsFilters = async () => {
    const response = await axios.get(`${apiURL}/v1/funding_rounds/filters`);
    return response.data;
  };

  // *** LEADERS ***

  const getLeaders = async (queryParams) => {
    const response = await axios.get(`${apiURL}/v1/leaders${queryParams}`);
    return response.data;
  };

  const getLeadersFilters = async () => {
    const response = await axios.get(`${apiURL}/v1/leaders/filters`);
    return response.data;
  };

  // *** USERS ***

  const getCurrentUser = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const currentUser = await axios.get(`${apiURL}/v1/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return currentUser.data;
  };

  const updateCurrentUser = async (body) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.put(`${apiURL}/v1/users/me`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const postUser = async (user) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/users`, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** FEEDBACKS ***

  const postFeedback = async (feedback) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/feedbacks`, feedback, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** INVEST FORMS ***

  const postInvestForm = async (investForm) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/invest_forms`, investForm, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** ADVANCED SEARCH FORMS ***

  const postAdvancedSearchForm = async (advancedSearchForm) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(
      `${apiURL}/v1/advanced_search_forms`,
      advancedSearchForm,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  // *** COMPANY TARGET CUSTOMERS FORMS ***

  const postCompanyTargetCustomers = async (targetCustomersForm) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(
      `${apiURL}/v1/companies_targeting_clients_forms`,
      targetCustomersForm,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  // *** VERSIONS ***

  const getVersions = async (queryParams: string = '') => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const versions = await axios.get(`${apiURL}/v1/versions?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return versions.data;
  };

  const approveCompanyCreate = async (newCompany) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const approve = await axios.post(
      `${apiURL}/v1/companies/approve_company_create`,
      newCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return approve.data;
  };

  const approveCompanyEdit = async (editedCompany) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const approve = await axios.post(
      `${apiURL}/v1/companies/approve_company_edit`,
      editedCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return approve.data;
  };

  const rejectCompanyCreate = async (newCompany) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const approve = await axios.delete(
      `${apiURL}/v1/companies/reject_company_create`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          newCompany
        }
      }
    );

    return approve.data;
  };

  const rejectCompanyEdit = async (editedCompany) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    const approve = await axios.post(
      `${apiURL}/v1/companies/reject_company_edit`,
      editedCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return approve.data;
  };

  // *** Submit Solutions ***

  const postSubmitSolutions = async (body) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience
      }
    });

    return await axios.post(`${apiURL}/v1/projects_forms`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  // *** SPC Tech Map ***

  const getCompaniesSpcTechMap = async () => {
    const spcTechMap = await axios.get(`${apiURL}/v1/companies/spc_tech_map`);
    return spcTechMap.data;
  };

  // *** Supplify GPT ***

  const postChat = async (query) => {
    const response = await axios.post(
      'https://supplify-gpt-api-f6b3021cea53.herokuapp.com/chat',
      query
    );

    return response.data;
  };

  return {
    getProjectById,
    getCompanyByUuid,
    postCompany,
    putCompany,
    getCompanyDetails,
    postCompanyDetails,
    putCompanyDetails,
    exportCompanies,
    postUser,
    postFeedback,
    postInvestForm,
    postAdvancedSearchForm,
    getCurrentUser,
    postCompanyTargetCustomers,
    getVersions,
    approveCompanyCreate,
    approveCompanyEdit,
    rejectCompanyCreate,
    rejectCompanyEdit,
    deleteCompany,
    getProjects,
    postProject,
    putProject,
    postSubmitSolutions,
    addNewCategory,
    addNewTag,
    getConstants,
    getCompaniesFilters,
    getFilters,
    getAllTags,
    getAllCategories,
    updateCurrentUser,
    getCompanies,
    getCompaniesSpcTechMap,
    getInvestors,
    getInvestorsFilters,
    getAcquisitions,
    getAcquisitionsFilters,
    getFundingRounds,
    getFundingRoundsFilters,
    getLeaders,
    getLeadersFilters,
    postChat
  };
};
