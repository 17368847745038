import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo
} from 'react';

import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';

import { defineRules } from '@/functions/defineRules';
import { UserProfileContext } from '@/contexts/UserProfileContext';

export const AbilityContext = createContext(undefined);
export const Can = createContextualCan(AbilityContext.Consumer);

type Props = {
  children: ReactNode;
};

export function AbilityProvider({ children }: Props) {
  const { user } = useContext(UserProfileContext);

  const ability = useMemo(() => {
    return new Ability();
  }, []);

  useEffect(() => {
    if (user) {
      ability.update(defineRules(user.permissions));
    }
  }, [ability, user]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}
