import { AbilityBuilder, Ability } from '@casl/ability';

export const defineRules = (userPermissions) => {
  const { can, cannot, rules } = new AbilityBuilder<Ability>(Ability);

  if (userPermissions) {
    for (const [key, value] of Object.entries(userPermissions)) {
      const subject = key.split('.')[0];
      const user_action = key.split('.')[1].slice(0, -1);

      if (value) {
        can(user_action, subject);
      } else {
        cannot(user_action, subject);
      }
    }
  }

  return rules;
};
