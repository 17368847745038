import { FC } from 'react';

import { Box, CircularProgress } from '@mui/material';

interface LoaderProps {
  position?: string;
}

const Loader: FC<LoaderProps> = (props) => {
  const position = props.position || 'fixed';
  return (
    <Box
      sx={{
        position: position,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
};

export default Loader;
