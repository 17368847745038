export enum Paths {
  Register = '/register',
  Companies = '/companies',
  NewCompany = '/companies/new',
  Investors = '/investors',
  FundingRounds = '/funding-rounds',
  Acquisitions = '/acquisitions',
  SearchRequest = '/search-request',
  Invest = '/invest'
}
