import React, { FC } from 'react';

import { NextRouter, withRouter } from 'next/router';

interface ErrorBoundaryProps {
  router: NextRouter;
  FallbackComponent: () => FC;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static props: ErrorBoundaryProps;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.FallbackComponent;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
