import { useState, ReactNode, createContext, useEffect } from 'react';

import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

import { isProduction } from '@/utils/isProduction';

export const SegmentContext = createContext(undefined);

type Props = {
  children: ReactNode;
};

export function SegmentProvider({ children }: Props) {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [writeKey, _setWriteKey] = useState(process.env.NEXT_PUBLIC_SEGMENT);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (isProduction) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line prefer-const
        let [response] = await AnalyticsBrowser.load({ writeKey });
        setAnalytics(response);
      }
    };
    loadAnalytics();
  }, [writeKey]);

  return (
    <SegmentContext.Provider value={{ analytics }}>
      {children}
    </SegmentContext.Provider>
  );
}
