import { createContext, ReactNode, useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { useApi } from '@/hooks/useApi';

export const AppDataContext = createContext(undefined);

type Props = {
  children: ReactNode;
};

export function AppDataProvider({ children }: Props) {
  const [appData, setAppData] = useState([]);

  const { getConstants } = useApi();

  const { data } = useQuery({
    queryKey: 'getConstants',
    queryFn: getConstants,
    staleTime: Infinity
  });

  useEffect(() => {
    if (data) {
      setAppData(data);
    }
  }, [data]);

  return (
    <AppDataContext.Provider value={{ appData }}>
      {children}
    </AppDataContext.Provider>
  );
}
